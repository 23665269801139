<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>

    <recess-divider />
    <h1>Mijn profiel</h1>
    <p>Op de profielpagina kunt u uw persoonlijke gegevens, die standaard bij een inschrijving worden gebruikt, bewerken</p>

    <template v-if="user">
      <h2>Persoonlijke informatie</h2>
      <div class="o-grid">
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="user.firstName"
            data-test="first-name"
            label-text="Voornaam *"
            :error-message="getErrorMessage('firstName')"
          />
        </div>

        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="user.middleName"
            data-test="middle-name"
            label-text="Tussenvoegsels"
          />
        </div>

        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="user.lastName"
            data-test="last-name"
            label-text="Achternaam *"
            :error-message="getErrorMessage('lastName')"
          />
        </div>

        <div
          v-if="$isAllowed('displayPersonalInformation')"
          class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
        >
          <recess-input
            v-model="user.initials"
            data-test="initials"
            label-text="Initialen"
            :error-message="getErrorMessage('initials')"
          />
        </div>

        <div
          v-if="$isAllowed('displayPersonalInformation') && $isAllowed('displayBirthDateField')"
          class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
        >
          <recess-date-picker 
            v-model="user.birthday"
            :default-value="user.birthday"
            placeholder="dd-mm-yyyy"
            label-text="Geboortedatum *"
            data-test="birth-day"
            :error-message="getErrorMessage('birthday')"
          />
        </div>

        <div 
          v-if="$isAllowed('displayPersonalInformation') && $isAllowed('displayBirthPlaceField')"
          class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
        >
          <recess-input
            v-model="user.birthplace"
            data-test="birth-place"
            label-text="Geboorteplaats *"
            placeholder-text="Geboorteplaats"
            :error-message="getErrorMessage('birthplace')"
          />
        </div>

        <div
          v-if="$isAllowed('displayPersonalInformation')"
          class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
        >
          <recess-select
            v-model="user.gender"
            :default-value="user.gender"
            data-test="gender"
            :options="genderOptions"
            label-text="Aanhef *"
            :error-message="getErrorMessage('gender')"
          />
        </div>

        <div
          v-if="$isAllowed('displayPersonalInformation')"
          class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
        >
          <recess-input
            v-model="user.honorific"
            data-test="honorific"
            label-text="Titulatuur"
          />
        </div>

        <div
          v-if="$isAllowed('displayPersonalInformation')"
          class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
        >
          <recess-select
            v-model="user.honorificAsPrefix"
            data-test="honorific-as-prefix"
            :default-value="honorificAsPrefix"
            select-option-text="Selecteer"
            :options="honorificAsPrefixOptions"
            label-text="Titel als voor-/achtervoegsel"
          />
        </div>

        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="user.employeeNumber"
            data-test="employee-number"
            label-text="Personeelsnummer"
            disabled
          />
        </div>
      </div>

      <recess-divider />

      <h2>Contact informatie</h2>
        

      <div class="o-grid u-position-relative">
        <transition
          name="fade"
          mode="out-in"
        >
          <loader-component
            v-if="isFetchingAddress"
            variant="small"
            color="primary"
            overlay="white"
          />
        </transition>
        <template v-if="$isAllowed('displayPersonalInformation')">
          <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
            <recess-input
              v-model="user.postalCode"
              data-test="postal-code"
              label-text="Postcode *"
              :error-message="getErrorMessage('postalCode')"
              @blur="getAddress()"
            />
          </div>

          <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
            <recess-input
              v-model="user.number"
              data-test="profile-number"
              label-text="Huisnummer *"
              :error-message="getErrorMessage('number')"
              @blur="getAddress()"
            />
          </div>

          <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
            <recess-input
              v-model="user.numberAdditive"
              data-test="number-additive"
              label-text="Toevoeging"
              :error-message="getErrorMessage('numberAdditive')"
            />
          </div>
          
          <div class="form-group o-grid__item grid-col-xs-12 grid-col-lg-4">
            <recess-input
              v-model="user.street"
              data-test="street"
              label-text="Straat *"
              :error-message="getErrorMessage('street')"
            />
          </div>

          <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
            <recess-input
              v-model="user.city"
              data-test="city"
              label-text="Plaats *"
              :error-message="getErrorMessage('city')"
            />
          </div>

          <div
            v-if="countriesOptions"
            class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4"
          >
            <recess-select
              v-model="user.country"
              data-test="country"
              label-text="Land *"
              :default-value="user.country || 'NL'"
              :options="countriesOptions"
              :error-message="getErrorMessage('country')"
            />
          </div>

          <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
            <recess-input
              v-model.trim="user.telephone"
              data-test="phone-number"
              label-text="Telefoonnummer"
              :error-message="getErrorMessage('telephone')"
            />
          </div>
        </template>

        <!-- see OPLZ-12498, disabled was associated to isSSOUser from usermodule -->
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            :value="user.emailAddress"
            :disabled="true"
            data-test="email-address"
            label-text="E-mailadres"
          />
        </div>

        <template v-if="emailTemplates && emailTemplates.length">
          <div class="form-group o-grid__item grid-col-12">
            <recess-toggle-input
              v-model="showEmailList"
              label-text="Ik wil graag kiezen welke e-mails ik ontvang"
              @input="val => {
                if(!val) {
                  enableAllEmails()
                }
              }"
            />
          </div>
          <transition
            name="fade"
            mode="out-in"
          >
            <email-listing
              v-if="showEmailList"
              :disabled-email-templates="disabledEmailTemplates"
              :email-templates="emailTemplates"
              class="form-group o-grid__item"
              @enable-all-emails="enableAllEmails()"
            />
          </transition>
        </template>
      </div>
    
  
      <template v-if="$isAllowed('assignDelegatee')">
        <recess-divider />

        <h2>Delegatie van mijn taken</h2>
        <p
          class="mb-3"
        >
          Met de delegatiefunctie kunt u iemand anders aanwijzen als (tijdelijke) vervanger voor de afhandelingen van uw goedkeuringstaken.
          Let op: indien u gedelegeerde taken heeft (u neemt bijv. een collega waar) dan is het voor u niet mogelijk taken te delegeren naar een andere collega.
        </p>

        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <recess-autocomplete
              v-model="formDelegateeEmail"
              data-test="delegatee-input"
              label-text="Waarnemer"
              placeholder="Naam of e-mail"
              item-key="userId"
              item-text="emailAddress"
              item-sub-text="fullName"
              :is-disabled="isUserADelegatee || hasActiveDelegator"
              :error-message="formDelegateeEmailValidationErrors"
              :on-query="queryUsers"
            />
          </div>
          <div class="col-12 col-md-auto">
            <recess-button
              v-if="user.activeDelegator"
              title="Delegatie intrekken"
              data-test="revoke-delegation-button"
              icon="cross"
              class="u-margin-top-1-8 w-100 mb-3"
              variant="tertiary"
              @click="revokeDelegation"
            />
          </div>
        </div>
      </template>

      <recess-button
        title="Opslaan"
        data-test="submit-button"
        class="u-position-right col-sm-12 col-md-auto mb-5"
        variant="primary"
        @click="submit"
      />
      <template v-if="showErrorMessage && !isLoading">
        <recess-alert
          v-for="(errorMessage, index) in errorMessages"
          :key="index"
          class="col-12 mt-5"
          type="error"
          :text="errorMessage"
        />
      </template>
      <recess-divider variant="large" />
      
      <template v-if="$isAllowed('displayCoodinators')">
        <h2>Coördinatoren</h2>
        
        <recess-card
          v-if="coordinators && coordinators.length"
          variant="variant1"
        >
          <recess-list>
            <recess-list-row
              v-for="coordinator in coordinators"
              :key="coordinator.id"
            >
              <recess-list-item>
                <p>{{ coordinator.fullName }}</p>
              </recess-list-item>
        
              <recess-list-item>
                <p>{{ coordinator.emailAddress }}</p>
              </recess-list-item>
            </recess-list-row>
          </recess-list>
        </recess-card>

        <recess-alert
          v-if="!coordinators && !isLoading"
          text="Er zijn geen coördinatoren."
        />
        <recess-divider />
    </template>

      <h2>Privacy</h2>
      <div
        v-if="$isAllowed('displayPersonalInformation')"
        class="o-grid"
      >
        <div class="form-group o-grid__item grid-col-xs-12 grid-col-md-6 grid-col-lg-4">
          <recess-input
            v-model="legalAgreementAcceptedDate"
            data-test="legal-agreement-accepted-date"
            label-text="Leesdatum gebruikersvoorwaarden"
            disabled
          />
        </div>
      </div>

      <div
        v-if="legalAgreements.length"
        class="mb-4"
      >
        <a
          v-for="agreement in legalAgreements"
          :key="agreement.id"
          :href="agreement.url"
          target="_blank"
          class="d-block mb-3"
        >
          <u>{{ agreement.name }}</u>
        </a>
      </div>
      
      <recess-button
        title="Download persoonlijke data"
        data-test="download-personal-data-button"
        class="u-position-left u-min-width-200 col-sm-12 col-md-auto mb-5"
        variant="tertiary"
        @click="downloadPersonalData"
      />
    </template>
    <edit-user-confirmation-modal
      :show-edit-user-confirmation="showEditUserConfirmation"
      @close="showEditUserConfirmation = false"
    />
    <request-personal-data-confirmation-modal
      :show-request-data-confirmation-message="showDownloadPersonalDataMessage"
      @close="showDownloadPersonalDataMessage = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapActions, mapGetters } from 'vuex'
import { required, numeric, requiredIf, maxLength } from 'vuelidate/lib/validators'
import { scrollTo } from 'vue-scrollto'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import {
    isValidDate,
    isPastDate,
    isValidPhoneNumber,
    validationMessages,
    isValidBirthdayDate
} from '../../../modules/validationsHelper'
import EditUserConfirmationModal from './EditUserConfirmationModal.vue'
import RequestPersonalDataConfirmationModal from './RequestPersonalDataConfirmationModal.vue'
import userClient from '../../../api/userClient'
import delegationClient from '../../../api/delegationClient'
import legalAgreementsClient from '../../../api/legalAgreementsClient'
import DateUtils from '../../../utils/DateUtils'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import delegatePerimeter from '../../../perimeters/DelegatePerimeter'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'
import routePerimeter from '../../../perimeters/routePerimeter'
import { getAddress, cancelFetchAddress } from "../../../api/addressLookupClient"
import EmailListing from './EmailListing.vue'
import emailTemplateClient from "../../../api/emailTemplateClient"

export default {
    components: {
        LoaderComponent,
        EditUserConfirmationModal,
        RequestPersonalDataConfirmationModal,
        EmailListing
    },
    perimeters: [featuresPerimeter, delegatePerimeter, routePerimeter],
    data() {
        return {
            showEmailList: false,
            validationMessages,
            showEditUserConfirmation: false,
            genderOptions: [
                { displayText: 'De heer', value: 'M' },
                { displayText: 'Mevrouw', value: 'F' }
            ],
            honorificAsPrefixOptions: [
                { displayText: 'Prefix', value: 'prefix' },
                { displayText: 'Suffix', value: 'suffix' }
            ],
            countriesOptions: null,
            coordinators: null,
            potentialDelegatees: [],
            isFetchingCountries: false,
            showErrorMessage: false,
            isSubmitting: false,
            showDownloadPersonalDataMessage: false,
            isFetchingCoordinators: false,
            isDelegating: false,
            errorMessages: ['Er is een fout opgetreden. Probeer het later opnieuw.'],
            formDelegateeEmail: null,
            isFetchingLegalAgreements: false,
            legalAgreements: [],
            isFetchingAddress: false,
            disabledEmailTemplates: [],
            emailTemplates: [],
            isFetchingEmailTemplates: false,
            isEnablingAllEmails: false,
            isFetchingPotentialDelegatees: false
        }
    },
    validations() {
        if (this.$isAllowed('displayPersonalInformation')) {
            const validationData = {
                user: {
                    firstName: { required },
                    lastName: { required },
                    city: { required },
                    country: { required },
                    postalCode: { required },
                    number: { required, numeric },
                    numberAdditive: { maxLength: maxLength(25) },
                    street: { required },
                    gender: { required },
                    birthplace: { 
                        required: requiredIf(() => {
                            return this.$isAllowed('displayBirthPlaceField')
                        })
                    },
                    telephone: { isValidPhoneNumber }
                },
                delegatee: {
                    isMatchingUserEmail() {
                        if (!this.formDelegateeEmail) return true
                        return this.isMatchingUserEmail
                    },
                    isNotDelegator() {
                        if (!this.formDelegateeEmail) return true
                        return !this.isPotentialDelegateeADelegator
                    }
                }
            }
            if (this.$isAllowed('displayBirthDateField')) {
                validationData.user.birthday = {
                    required: requiredIf(() => {
                        return this.isDiplomaOrAccreditationDegree
                    }),
                    isValidDate,
                    isPastDate,
                    isValidBirthdayDate
                }
            }
            return validationData
        }
        return {
            user: {
                firstName: { required },
                lastName: { required }
            },
            delegatee: {
                isMatchingUserEmail() {
                    if (!this.formDelegateeEmail || this.formDelegateeEmail.length < 3) return true
                    return this.isMatchingUserEmail
                },
                isNotDelegator() {
                    if (!this.formDelegateeEmail) return true
                    return !this.isPotentialDelegateeADelegator
                }
            }
        }
    },
    computed: {
        ...mapState('userModule', ['user', 'isFetchingUser']),
        ...mapGetters('userModule', ['companyTeamleadersIds', 'isUserADelegatee', 'hasActiveDelegator']),
        isLoading() {
            return this.isFetchingCountries 
            || this.isFetchingCoordinators 
            || this.isSubmitting 
            || this.isDelegating 
            || this.isFetchingUser 
            || this.isFetchingLegalAgreements
            || this.isFetchingEmailTemplates
            || this.isEnablingAllEmails
        },
        legalAgreementAcceptedDate() {
            return DateUtils.formatDate(this.user.legalAgreementAcceptedDate)
        },
        honorificAsPrefix() {
            if (this.user.honorificAsPrefix !== null)
                return this.user.honorificAsPrefix ? 'prefix' : 'suffix'
            return null
        },
        isMatchingUserEmail() {
            return this.potentialDelegatees.some(x => x.emailAddress === this.formDelegateeEmail)
        },
        isPotentialDelegateeADelegator() {
            const matcheduser = this.potentialDelegatees?.find(x => x.emailAddress === this.formDelegateeEmail)

            return !!matcheduser?.activeDelegator 
        },
        formDelegateeEmailValidationErrors() {
            if(!this.isFetchingPotentialDelegatees && !this.$v.delegatee?.isMatchingUserEmail) {
                return 'Kies een werknemer uit de lijst of laat het veld leeg'
            }  

            if(!this.$v.delegatee?.isNotDelegator) {
                return 'Deze gebruiker heeft zijn taken gedelegeerd aan iemand anders'
            }

            return ''
        },        
        isBirthDateInputDisplayed() {
            return this.$isAllowed('displayPersonalInformation') && this.$isAllowed('displayBirthDateField')
        }
    },
    watch: {
        isFetchingUser(val) {
            if (!val && this.user?.activeDelegator)
                this.formDelegateeEmail = this.user.activeDelegator.delegateeEmail
        }
    },
    async mounted() {
        const promises = [
            this.getCountries(),
            this.getCoordinators(),
            this.fetchLegalAgreements(),
            this.initializeEmailTemplates()
        ]

        // set initial delegatee email
        if(this.$isAllowed('assignDelegatee')) {
            this.formDelegateeEmail = this.user?.activeDelegator?.delegateeEmail
            promises.push(this.queryUsers(this.formDelegateeEmail))
        }

        await Promise.all(promises)
    },
    beforeDestroy() {
        this.fetchUser()
    },
    methods: {
        ...mapActions('userModule', ['fetchUser', 'updateUser']),
        async getAddress() {
            try {
                // Cancel previous request
                cancelFetchAddress()
                this.isFetchingAddress = true
                if (this.user.postalCode
                 && this.user.number 
                 && !this.$v.user.postalCode.$invalid 
                 && !this.$v.user.number.$invalid) {
                    const response = await getAddress(this.user.postalCode, this.user.number)

                    if(response) {
                        this.user.street = response.streetName
                        this.user.city = response.city
                        this.user.country = "NL"
                    }
                }
            } catch {
                // No need to throw error to the console
            } finally {
                this.isFetchingAddress = false
            }
           
        },
        async fetchLegalAgreements() {
            try {
                this.isFetchingLegalAgreements = true
            
                const response = await  legalAgreementsClient.getLegalAgreements(true)

                this.legalAgreements = response.filter(x => x.isEnabled)
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingLegalAgreements = false
            } 
        },
        async getCountries() {
            try {
                this.isFetchingCountries = true

                const countries = await userClient.getCountries()
                this.countriesOptions = countries?.map(country => {
                    return {
                        displayText: country.displayValue,
                        value: country.value
                    }
                })
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingCountries = false
            }
        },
        async getCoordinators() {
            // Only fetch coordinators if the feature toggle is enabled
            if(!this.$isAllowed('displayCoodinators')) {
              return
            }

            try {
                this.isFetchingCoordinators = true

                const response = await  userClient.getCoordinators()
                this.coordinators = response?.items ?? null
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingCoordinators = false
            }
        },
        async queryUsers(query) {
            if (!query) return []
            try {
                // Cancel previous pending request
                userClient.cancelFetchSearch()
                this.isFetchingPotentialDelegatees = true
                
                const filters = [
                    {
                        keys: ['firstName', 'middleName', 'lastName', 'emailAddress'],
                        operator: ODataFilterOperations.CONTAINS,
                        value: query,
                        keyCombination: 'or'
                    },
                    {
                        keys: 'accountId',
                        operator: ODataFilterOperations.EQUALS,
                        value: this.user.accountId
                    },
                    {
                        keys: 'userId',
                        operator: ODataFilterOperations.NOTEQUALS,
                        value: this.user.userId
                    },
                    {
                        keys: 'isEnabled',
                        operator: ODataFilterOperations.EQUALS,
                        value: true
                    }
                ]

                const oDataFilters = filters.map(f => {
                    return new ODataQueryFilter(f.keys, f.operator, f.value, f.keyCombination)
                })

                const dataQuery = new ODataQueryBuilder()
                    .setSort('firstName', 'asc')
                    .setPagination(0, 5)
                    .addFilters(oDataFilters)
                    .setIncludes('DelegationsAsDelegator')
                    .build()

                const response = await userClient.searchUsersBasicInfo(dataQuery)
                this.potentialDelegatees = response?.items
                return this.potentialDelegatees
            } catch (error) {
                // Only throw error is the request is not cancelled
                if (!axios.isCancel(error)) {
                    console.error(error)
                }
                return []
            } finally {
                this.isFetchingPotentialDelegatees = false
            }
        },
        async revokeDelegation() {
            // clear the form email field
            this.formDelegateeEmail = null
            // then proceed to remove the delegation
            await this.removeDelegation()
        },
        async submit() {
            try {
                this.showErrorMessage = false
                // Reset error message to the default one
                this.errorMessages = ['Er is een fout opgetreden. Probeer het later opnieuw.']

                this.isSubmitting = true
                this.$v.$touch()
                if (this.$v.$invalid) {
                    scrollTo('.has-error', {
                        offset: -500
                    })
                    return
                }

                // Update user
                await this.updateUser(this.isBirthDateInputDisplayed)

                // Check delegation removal
                await this.removeDelegation()

                this.showEditUserConfirmation = true

                // Check delegation creation
                if(!this.isUserADelegatee) await this.postDelegation()
            } catch (error) {
                this.errorMessages = Object.values(error?.response?.data).map(
                    errorMessage => errorMessage[0]
                ) ?? ['Er is een fout opgetreden. Probeer het later opnieuw.']
                this.showErrorMessage = true
                throw new Error(error)
            } finally {
                this.isSubmitting = false
            }
        },
        async postDelegation() {
            try {
                // Check if the delegatee is filled or is not same of the initial one
                if (
                    !this.formDelegateeEmail ||
                    !this.isMatchingUserEmail ||
                    this.formDelegateeEmail === this.user?.activeDelegator?.delegateeEmail
                )
                    return

                const delegatee = this.potentialDelegatees.find(
                    x => x.emailAddress === this.formDelegateeEmail
                )

                if (delegatee) {
                    const postDelegateeData = {
                        delegateeId: delegatee.userId
                    }

                    this.isDelegating = true

                    await delegationClient.postDelegation(postDelegateeData)

                    this.fetchUser()
                }
            } catch (error) {
                this.errorMessages = Object.values(error?.response?.data).map(
                    errorMessage => errorMessage[0]
                ) ?? ['Er is een fout opgetreden. Probeer het later opnieuw.']
                this.showErrorMessage = true
            } finally {
                this.isDelegating = false
            }
        },
        async removeDelegation() {
            try {
                // Check if delegatee is removed
                if (this.user?.activeDelegator && !this.formDelegateeEmail) {
                    this.isDelegating = true
                    await delegationClient.disableDelegationByDelegationId(
                        this.user.activeDelegator?.id
                    ).then()
                        .then(() => {this.isDelegating = false})

                    await this.fetchUser()
                }
            } catch (error) {
                this.showErrorMessage = true
                throw new Error(error)
            }
        },
        async initializeEmailTemplates() {
            try {
                this.isFetchingEmailTemplates = true
                const toggleableEmails = await emailTemplateClient.getToggleableEmailTemplates()
                this.disabledEmailTemplates = await emailTemplateClient.getDisabledEmailTemplates()
                this.emailTemplates =  toggleableEmails?.map(x => {
                    return {
                        templateId: x.templateId,
                        description: x.description, 
                        isActive: !this.disabledEmailTemplates?.includes(x.templateId)
                    }
                })
                
                this.showEmailList = !!this.disabledEmailTemplates?.length
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingEmailTemplates = false
            }
        },
        async enableAllEmails() {
            try {
                this.isEnablingAllEmails = true
                await emailTemplateClient.toggleEmailTemplate([])
                await this.initializeEmailTemplates()
                this.showEmailList = false
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isEnablingAllEmails = false
            }
        },
        getErrorMessage(fieldName) {
            const fieldValidation = this.$v.user[fieldName]
            const fieldValue = this.user[fieldName]

            // Check Required - need to compare with false because the field can be undefined
            if (fieldValidation && fieldValidation.$error && fieldValidation.required === false) {
                return this.validationMessages.required[fieldName]
            }

            // Check Invalid
            if (fieldValidation && fieldValidation.$invalid && fieldValue) {
                return this.validationMessages.invalid[fieldName]
            }

            return null
        },
        async downloadPersonalData() {
            this.showErrorMessage = false
            try {
                await userClient.downloadPersonalData()
                this.showDownloadPersonalDataMessage = true
            } catch (err) {
                throw new Error(err)
            }
        }
    }
}
</script>