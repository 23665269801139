import BasePerimeter from './BasePerimeter'
import * as featureToggles from '../constants/featureToggles'
import * as contactInformationSettings from '../constants/contactInformationSettings'
import { AbcRequestSettings, AbcRequestType } from '../constants/abcRequestSettings'

export default class FeaturesBasePerimeter extends BasePerimeter {
    isHomeLinkDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.HOMELINK)
    }

    isMyDevelopmentLinkDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.MYDEVELOPMENTLINK)
    }

    isFAQLinkDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.FAQLINK)
    }

    isSearchLinkDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.SEARCHLINK)
    }

    isReportLinkDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.REPORTLINK) || this.isReportDisabled()
    }

    isFooterMenuDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.FOOTER)
    }

    isFollowingCourseDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.FOLLOWCOURSE)
    }

    isPersonalInformationDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.PERSONALINFORMATION)
    }

    isPricingInformationDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.PRICINGINFORMATION)
    }

    isLogoutLinkDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.LOGOUTLINK)
    }

    isChangePasswordLinkDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.CHANGEPASSWORDLINK)
    }

    isPERemindersManageableByCoordinatorDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.PEREMINDERSMANAGEABLEBYCOORDINATOR)
    }

    isManualStudyContractUploadDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.MANUALSTUDYCONTRACTUPLOAD)
    }

    isCompletedLearningPathStyleDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.COMPLETEDLEARNINGPATHSTYLE)
    }

    isLearningPathDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.LEARNINGPATH)
    }

    isCostCenterDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.COSTCENTER)
    }

    isPONumberDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.PONUMBER)
    }

    isBirthDateFieldDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.BIRTHDATEFIELD)
    }

    isBirthPlaceFieldDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.BIRTHPLACEFIELD)
    }

    isStudyContractDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.STUDYCONTRACT)
    }

    isPERemindersManageableByTeamLeaderDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.PEREMINDERSMANAGEABLEBYTEAMLEADER)
    }

    // Not feature toggles, but account settings
    isBudgetsDisabled() {
        return !this.child?.accountSettings?.isBudgetEnabled
    }

    isShareCourseDisabled() {
        return !this.child?.accountSettings?.shareCourseScope
    }

    isKeyAccountsDisabled() {
        return !this.child?.accountSettings?.isKeyAccountEnabled
    }

    isContactInformationDisabled() {
        return this.child?.accountSettings?.contactInformation === contactInformationSettings.DISABLED
    }

    isContactInformationDisabledForStudents() {
        return this.child?.accountSettings?.contactInformation === contactInformationSettings.DISABLEDFORSTUDENT && !this.isCapableOfTeamLeading()
    }

    isABCRequestDisabled() {
        return this.child?.accountSettings?.abcRequest === AbcRequestSettings.Disabled || (this.child?.accountSettings?.abcRequest === AbcRequestSettings.DisabledForStudent && !this.isCapableOfTeamLeading())
    }

    isIncompanyABCRequestDisabled() {
        return this.isABCRequestDisabled() || (this.child?.accountSettings?.abcRequestType === AbcRequestType.Standard)
    }

    isStandardABCRequestDisabled() {
        return this.isABCRequestDisabled() || (this.child?.accountSettings?.abcRequestType === AbcRequestType.Incompany)
    }

    isShowContactAlternativeTextDisabled() {
        return this.isContactInformationDisabledForStudents() && !this.child?.accountSettings?.contactAlternativeText
    }

    isReportDisabled() {
        return !this.isCoordinator() && !this.isAccountReporter() && (!this.isTeamLeader() || !this.child?.accountSettings?.showReportForTeamLeaders)
    }

    isShowCompletedColumnDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.COMPLETEDCOLUMN)
    }

    isRecentlyVisitedCourseDisabled() {
        return !this.child?.accountSettings?.recentlyVisitedCourses
    }

    isPERemindersDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.SHOWPEREMINDERS)
    }

    isSegmentImageDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.SHOWSEGMENTIMAGES)
    }

    isPEReminderCertificateDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.UPLOADPEREMINDERCERTIFICATION)
    }

    isReasonForEnrollingDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.SHOWREASONFORENROLLING)
    }

    isContactSubmenuDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.SHOWCONTACTSUBMENU)
    }

    isEnrollmentPersonalDataDisabled() {
        return this.child?.accountSettings?.hideEnrollmentPersonalData
    }

    isSocialLearningDisabled() {
        return !this.child?.accountSettings?.isSocialLearningEnabled
    }

    isLearningResultDisabled() {
        return !this.child?.accountSettings?.learningResult
    }

    isAIDisabled() {
        return !this.child?.accountSettings?.isAICourseSuggestionEnabled || (this.isTeamLeader() && !this.isCoordinator() && this.child?.accountSettings?.teamLeaderSelfSearchDisabled)
    }

    isProjectRequestDisabled() {
        return !this.child?.accountSettings?.isProjectsEnabled || (!this.isProjectInitiator() && !this.isProjectApprover())
    }

    isShowCoordinatorsDisabled() {
        return this.child?.account?.accountDisabledFeatures?.includes(featureToggles.SHOWCOORDINATORSONPROFILEPAGE)
    }
}
