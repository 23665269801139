import FeaturesBasePerimeter from './FeaturesBasePerimeter'

export default new FeaturesBasePerimeter({
    purpose: 'featureDisplay',

    cannot: {
        displayHomeLink() {
            return this.isHomeLinkDisabled()
        },
        displayMyDevelopmentLink() {
            return this.isMyDevelopmentLinkDisabled()
        },
        displayContactInformation() {
            return this.isContactInformationDisabled()
        },
        displayContactInformationForStudents() {
            return this.isContactInformationDisabledForStudents()
        },
        displayABCRequest() {
            return this.isABCRequestDisabled()
        },
        displayABCRequestIncompany() {
            return this.isIncompanyABCRequestDisabled()
        },
        displayABCRequestStandard() {
            return this.isStandardABCRequestDisabled()
        },
        displayFAQLink() {
            return this.isFAQLinkDisabled()
        },
        displaySearchLink() {
            return this.isSearchLinkDisabled()
        },
        displayReportLink() {
            return this.isReportLinkDisabled()
        },
        displayFooterMenu() {
            return this.isFooterMenuDisabled()
        },
        displayNavigationBar() {
            return (
                !this.isCapableOfTeamLeading() &&
                this.isHomeLinkDisabled() &&
                this.isMyDevelopmentLinkDisabled() &&
                this.isReportLinkDisabled() &&
                this.isFAQLinkDisabled() &&
                this.isSearchLinkDisabled() && 
                (this.isContactInformationDisabled() ||
                this.isContactInformationDisabledForStudents())
            )
        },
        followCourse() {
            return this.isFollowingCourseDisabled()
        },
        displayPersonalInformation() {
            return this.isPersonalInformationDisabled()
        },
        displayPricingInformation() {
            return this.isPricingInformationDisabled()
        },
        displayLogoutLink() {
            return this.isLogoutLinkDisabled()
        },
        displayChangePasswordLink() {
            return this.isChangePasswordLinkDisabled()
        },
        managePERemindersByCoordinator() {
            return this.isPERemindersManageableByCoordinatorDisabled()
        },
        managePERemindersByTeamLeader() {
            return this.isPERemindersManageableByTeamLeaderDisabled()
        },
        displayManualUploadStudyContract() {
            return this.isManualStudyContractUploadDisabled()
        },
        displayCompletedLearningPathStyle() {
            return this.isCompletedLearningPathStyleDisabled()
        },
        displayLearningPath() {
            return this.isLearningPathDisabled()
        },
        displayCostCenter() {
            return this.isCostCenterDisabled()
        },
        displayPONumber() {
            return this.isPONumberDisabled()
        },
        displayBudgets() {
            return this.isBudgetsDisabled()
        },
        displayShareCourse() {
            return this.isShareCourseDisabled()
        },
        displayBirthDateField() {
            return this.isBirthDateFieldDisabled()
        },
        displayBirthPlaceField() {
            return this.isBirthPlaceFieldDisabled()
        },
        displayStudyContract() {
            return this.isStudyContractDisabled()
        },
        displayKeyAccounts() {
            return this.isKeyAccountsDisabled()
        },
        displayContactAlternativeText() {
            return (
                this.isShowContactAlternativeTextDisabled() &&
                this.isContactInformationDisabledForStudents()
            )
        },
        displayReports() {
            return this.isReportDisabled()
        },
        displayCompletedColumn() {
            return this.isShowCompletedColumnDisabled()
        },
        displayRecentlyVisitedCourses() {
            return this.isRecentlyVisitedCourseDisabled()
        },
        displayPERemindersFeature() {
            return this.isPERemindersDisabled()
        },
        displaySegmentImages() {
            return this.isSegmentImageDisabled()
        },
        displayEnrollReason() {
            return this.isReasonForEnrollingDisabled()
        },
        displayContactSubmenu() {
            return this.isContactSubmenuDisabled()
        },
        displayEnrollmentPersonalData(enrollment) {
            return this.isPersonalInformationDisabled() 
            || (this.isEnrollmentPersonalDataDisabled()
            && this.child?.user?.userId !== enrollment.userId)
        },
        displaySocialLearning() {
            return this.isSocialLearningDisabled()
        },
        displayLearningResult() {
            return this.isLearningResultDisabled()
        },
        displayAI() {
            return this.isAIDisabled()
        },
        displayProjectRequest() {
            return this.isProjectRequestDisabled()
        },
        displayCoodinators() {
            return this.isShowCoordinatorsDisabled()
        }
    }
})
