export const HOMELINK = 'HOMELINK'
export const MYDEVELOPMENTLINK = 'MYDEVELOPMENTLINK'
export const FAQLINK = 'FAQLINK'
export const REPORTLINK = 'REPORTLINK'
export const FOOTER = 'FOOTER'
export const FOLLOWCOURSE = 'FOLLOWCOURSE'
export const PERSONALINFORMATION = 'PERSONALINFORMATION'
export const PRICINGINFORMATION = 'PRICINGINFORMATION'
export const LOGOUTLINK = 'LOGOUTLINK'
export const CHANGEPASSWORDLINK = 'CHANGEPASSWORDLINK'
export const PEREMINDERSMANAGEABLEBYCOORDINATOR = 'PEREMINDERSMANAGEABLEBYCOORDINATOR'
export const ABCREQUEST = 'ABCREQUEST'
export const MANUALSTUDYCONTRACTUPLOAD = 'MANUALSTUDYCONTRACTUPLOAD'
export const COMPLETEDLEARNINGPATHSTYLE = 'COMPLETEDLEARNINGPATHSTYLE'
export const LEARNINGPATH = 'LEARNINGPATH'
export const COSTCENTER = 'COSTCENTER'
export const PONUMBER = 'PONUMBER'
export const BIRTHPLACEFIELD = 'BIRTHPLACEFIELD'
export const BIRTHDATEFIELD = 'BIRTHDATEFIELD'
export const STUDYCONTRACT = 'STUDYCONTRACTTAB'
export const SEARCHLINK = 'SEARCHPAGELINK'
export const COMPLETEDCOLUMN = 'SHOWCOMPLETEDCOLUMNINACTIVECOURSES'
export const SHOWPEREMINDERS = 'SHOWPEREMINDERS'
export const SHOWSEGMENTIMAGES = 'SHOWSEGMENTIMAGES'
export const PEREMINDERSMANAGEABLEBYTEAMLEADER = 'PEREMINDERSMANAGEABLEBYTEAMLEADER'
export const UPLOADPEREMINDERCERTIFICATION = 'UPLOADPEREMINDERCERTIFICATION'
export const SHOWREASONFORENROLLING = 'SHOWREASONFORENROLLING'
export const SHOWCONTACTSUBMENU = 'SHOWCONTACTSUBMENU'
export const SHOWCOORDINATORSONPROFILEPAGE = 'SHOWCOORDINATORSONPROFILEPAGE'

export default {
    HOMELINK,
    MYDEVELOPMENTLINK,
    FAQLINK,
    REPORTLINK,
    FOOTER,
    FOLLOWCOURSE,
    PERSONALINFORMATION,
    PRICINGINFORMATION,
    LOGOUTLINK,
    CHANGEPASSWORDLINK,
    PEREMINDERSMANAGEABLEBYCOORDINATOR,
    MANUALSTUDYCONTRACTUPLOAD,
    COMPLETEDLEARNINGPATHSTYLE,
    LEARNINGPATH,
    COSTCENTER,
    PONUMBER,
    BIRTHPLACEFIELD,
    BIRTHDATEFIELD,
    STUDYCONTRACT,
    SEARCHLINK,
    COMPLETEDCOLUMN,
    SHOWPEREMINDERS,
    SHOWSEGMENTIMAGES,
    UPLOADPEREMINDERCERTIFICATION,
    SHOWREASONFORENROLLING,
    SHOWCONTACTSUBMENU,
    SHOWCOORDINATORSONPROFILEPAGE
}
