<script lang="js">
import { computed } from 'vue'
import useStore from '../../../composables/useStore'
import useRoute from '../../../composables/useRoute'

export default {
    setup() {
        const store = useStore()
        const route = useRoute()

        const isSmartSearchStickyVisible = computed(() => store?.state.searchModule.isSmartSearchStickyVisible)
        const searchTerm = computed(() => route.value.query.q)

        async function onSmartSearchOptionClick(useSmartSearch) {
            if(!store) return

            // hide the sticky bar
            await store.commit('searchModule/setSmartSearchStickyVisible', false)

            // sets whether the smart search should be used or not
            await store.commit('searchModule/setUseSmartSearch', useSmartSearch)
            
            this.$gtag.event(useSmartSearch ? 'ai-synonyms-accepted' : 'ai-synonyms-rejected')

            if (!useSmartSearch || !searchTerm.value) return

            await store.dispatch('searchModule/fetchResults')
        }

        return {
            isSmartSearchStickyVisible,
            onSmartSearchOptionClick
        }
    }
}
</script>

<template>
    <recess-card v-if="isSmartSearchStickyVisible" class="ai-sticky-bar" variant="variant1">
        <span class="font-weight-bold align-content-center">
            Ben je blij met dit zoekresultaat?
        </span>
        <div class="ai-sticky-bar__options">
            <recess-button
                class="ai-sticky-bar__options__option"
                title="Ja / verberg"
                variant="secondary"
                data-test="ai-sticky-bar-hide"
                @click="() => onSmartSearchOptionClick(false)"
            />
            <recess-button
                class="ai-sticky-bar__options__option"
                title="Nee / slim zoeken (AI)"
                variant="secondary"
                data-test="ai-sticky-bar-smart-search"
                @click="() => onSmartSearchOptionClick(true)"
            />
        </div>
    </recess-card>
</template>
